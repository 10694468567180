import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js';

// Connects to data-controller="chart"
export default class extends Controller {
  static values = {
    color: String,
    scores: Array
  }
  static targets = ["canvas"]

  connect() {
    this.renderDonut();
  }

  renderDonut() {
    if(!this.hasCanvasTarget) return;

    console.log(this.hasColorValue)
    const ctx = this.canvasTarget.getContext('2d');
    const scores = this.scoresValue
    const color = this.colorValue

    this.graph = new Chart(ctx, {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: scores,
            backgroundColor: [color, "#d0d0d0"],
            borderWidth: 0,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        tooltips: {
          enabled: false,
        },
        events: [],
        plugins: {
          labels: {
            render: () => "",
          },
        },
      }
    });
  }
}
